<template>
  <div class="work_container">
    <div class="work_page">
      <div class="echarts_container track_style">
        <div class="echarts_title_container flex_between">
          <div class="echarts_title">统调运行数据</div>

          <div class="flex_start">
            <el-select
              v-model="dateTrack"
              placeholder="请选择"
              @change="changeTrack"
            >
              <el-option
                v-for="item in dateType.options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>

        <div ref="track" id="track"></div>
      </div>
      <div class="echarts_container track_style">
        <div class="echarts_title_container flex_between">
          <div class="echarts_title">价格走线图</div>
        </div>

        <div ref="temperature" id="temperature"></div>
      </div>
    </div>

    <div class="work_page">
      <div class="echarts_container timely_style">
        <div class="echarts_title_container flex_between">
          <div class="echarts_title">西南降水与西电情况</div>
        </div>

        <div ref="timely" id="timely"></div>
      </div>

      <div class="echarts_container timely_style">
        <div class="echarts_title_container flex_between">
          <div class="echarts_title">机组容量信息</div>

          <div class="crew flex_start">
            <el-select
              v-model="crew"
              placeholder="请选择"
              @change="changeCrewType"
            >
              <el-option
                v-for="item in crewList.options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
        <el-radio-group
          v-model="crewRadio"
          fill="#409EFF"
          size="large"
          class="crewRadio"
          @change="changeCrew"
        >
          <el-radio-button label="预测" />
          <el-radio-button label="实际" />
        </el-radio-group>

        <div ref="unit" id="unit"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, ref, getCurrentInstance, nextTick, onMounted } from "vue";
let now = new Date(2020, 9, 3);
let oneDay = 24 * 3600 * 1000;
let value = Math.random() * 1000;
let value2 = Math.random() * 900;

export default {
  name: "market",
  setup() {
    const { proxy } = getCurrentInstance(); // proxy是组件实例化对象

    const dateType = reactive({
      options: [
        { label: "日", value: 1 },
        { label: "月", value: 2 },
        { label: "年", value: 3 },
      ],
    });
    let dateTrack = ref(1);
    let dateTemperature = ref(1);

    let crew = ref(1);
    let crewRadio = ref("预测");

    const crewList = reactive({
      options: [
        { label: "检修", value: 1 },
        { label: "备用", value: 2 },
        { label: " 必开必停", value: 3 },
      ],
    });

    // 统调运行数据
    let track = reactive({
      options: {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          top: "20%",
          left: "5%",
          right: "8%",
          bottom: "30",
        },
        legend: {
          data: ["预测数据", "实际数据"],
          textStyle: {
            color: "#FFFFFF",
          },
        },
        xAxis: {
          type: "category",
          data: [
            "00:00",
            "01:00",
            "02:00",
            "03:00",
            "04:00",
            "05:00",
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
          ],
          boundaryGap: false,
          axisLine: { onZero: false, show: true },
          axisLabel: {
            //轴上的字 字体、样式
            textStyle: {
              color: "#FFFFFF",
            },
          },
        },
        yAxis: {
          type: "value",
          name: "单位：MW",
          nameTextStyle: {
            color: "#FFFFFF",
            align: "left",
          },
          axisLine: { onZero: false, show: true },
          axisLabel: {
            //轴上的字 字体、样式
            textStyle: {
              color: "#FFFFFF",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },
        series: [
          {
            name: "预测数据",
            data: [1, 15, 52, 100, 23, 55, 75, 23, 55, 23, 5],
            type: "line",
            smooth: true,
            areaStyle: {
              opacity: 0.4,
              color: proxy.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#0081FF",
                },
                {
                  offset: 1,
                  color: "#1F2935",
                },
              ]),
            },
          },
          {
            name: "实际数据",
            data: [55, 75, 23, 55, 23, 5, 0, 67, 23, 100, 5],
            type: "line",
            smooth: true,
            areaStyle: {
              opacity: 0.4,
              color: proxy.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#00E081",
                },
                {
                  offset: 1,
                  color: "#09B56C",
                },
              ]),
            },
          },
        ],
      },
    });

    const trackChart = async () => {
      // 渲染图表
      const chart = proxy.$echarts.init(document.getElementById("track"));
      chart.setOption(track.options);
      window.onresize = () => {
        chart.resize();
      };
    };

    const randomData = (types) => {
      if (types == 1) {
        value = value + Math.random() * 21 - 10;
        now = new Date(+now + oneDay);

        return {
          name: now.toString(),

          value: [
            [now.getFullYear(), now.getMonth() + 1, now.getDate()].join("/"),
            Math.round(value),
          ],
        };
      } else {
        value2 = value2 + Math.random() * 46 - 10;
        now = new Date(+now + oneDay);
        return {
          name: now.toString(),

          value: [
            [now.getFullYear(), now.getMonth() + 1, now.getDate()].join("/"),
            Math.round(value2),
          ],
        };
      }
    };

    let dataList = reactive({ maps: [] });
    // 温度走线数据
    let temperature = reactive({
      options: {
        tooltip: {
          trigger: "axis",
        },
        grid: {
          top: "20%",
          left: "5%",
          right: "8%",
          bottom: "30",
        },
        legend: {
          data: ["日前价格", "实际价格"],
          textStyle: {
            color: "#FFFFFF",
          },
        },
        xAxis: {
          type: "time",
          axisLabel: {
            //轴上的字 字体、样式
            textStyle: {
              color: "#FFFFFF",
            },
          },

          splitLine: {
            show: false,
          },
        },
        yAxis: {
          type: "value",
          name: "单位：元/WMh",
          nameTextStyle: {
            color: "#FFFFFF",
            align: "left",
          },
          axisLine: { onZero: false, show: true },
          axisLabel: {
            //轴上的字 字体、样式
            textStyle: {
              color: "#FFFFFF",
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },

        series: [
          {
            data: [],
            name: "日前价格",
            type: "line",
            showSymbol: false,
            color: "#6355FF",
            encode: {
              y: "单位：元/WMh",

              tooltip: ["单位：元/WMh"],
            },
          },
          {
            name: "实际价格",
            data: [],
            type: "line",
            showSymbol: false,
            color: "#36D1FF",
            encode: {
              y: "单位：元/WMh",
              itemName: "Year",
              tooltip: ["单位：元/WMh"],
            },
          },
        ],
      },
    });

    const temperatureChart = () => {
      // 渲染图表
      const chart = proxy.$echarts.init(document.getElementById("temperature"));
      chart.setOption(temperature.options);

      window.onresize = () => {
        chart.resize();
      };
    };

    let timely = reactive({
      options: {
        animationDuration: 5000,
        grid: {
          top: "20%",
          // left:'15%',
          right: "10%",
          bottom: "30",
        },
        tooltip: {
          // order: 'valueDesc',
          trigger: "axis",
          formatter: function (params) {
            let relVal = params[0].name;
            for (var i = 0, l = params.length; i < l; i++) {
              relVal +=
                "<br/>" +
                params[i].marker +
                params[i].seriesName +
                "   " +
                params[i].value +
                "万";
            }
            return relVal;
          },
        },
        legend: {
          data: ["西电-预测", "西电-实际", "降雨量"],
          textStyle: {
            color: "#FFFFFF",
          },
        },
        xAxis: {
          type: "category",
          nameLocation: "middle",
          data: [
            "08/07",
            "08/08",
            "08/09",
            "08/10",
            "08/11",
            "08/12",
            "08/13",
            "08/14",
          ],
          boundaryGap: false,
          axisLine: { onZero: false, show: true },
          axisLabel: {
            //轴上的字 字体、样式
            textStyle: {
              color: "#FFFFFF",
            },
          },
        },
        yAxis: {
          type: "value",
          name: "降水量:mm   西电:WMh",
          nameTextStyle: {
            color: "#FFFFFF",
            align: "left",
          },

          axisLine: { onZero: false, show: true },
          axisLabel: {
            //轴上的字 字体、样式
            textStyle: {
              color: "#FFFFFF",
            },
          },

          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed",
            },
          },
        },
        series: [
          {
            name: "西电-预测",
            emphasis: {
              focus: "series",
            },

            data: [1, 15, 52, 100, 23, 55, 75, 23, 55, 23, 5],
            type: "line",
            smooth: false,
            endLabel: {
              show: true,
              formatter: function (params) {
                return params.value + "万";
              },
            },
          },
          {
            name: "西电-实际",
            emphasis: {
              focus: "series",
            },

            data: [10, 20, 30, 40, 50, 60, 70, 80, 90, 95, 100],
            type: "line",
            smooth: false,
            endLabel: {
              show: true,
              formatter: function (params) {
                return params.value + "万";
              },
            },
          },
          {
            name: "降雨量",
            emphasis: {
              focus: "series",
            },

            data: [0, 15, 30, 25, 12, 88, 95, 92, 98, 99, 5],
            type: "line",
            smooth: false,

            endLabel: {
              show: true,
              formatter: function (params) {
                return params.value + "万";
              },
            },
          },
        ],
      },
    });
    const timelyChart = async () => {
      // 渲染图表
      const chart = proxy.$echarts.init(document.getElementById("timely"));
      chart.setOption(timely.options);
      window.onresize = () => {
        chart.resize();
      };
    };

    let unit = reactive({
      options: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        legend: {
          data: ["预测"],
          textStyle: {
            color: "#FFFFFF",
          },
        },
        xAxis: [
          {
            type: "category",
            data: [
              "08/07",
              "08/08",
              "08/09",
              "08/10",
              "08/11",
              "08/12",
              "08/13",
              "08/14",
            ],
            axisTick: {
              alignWithLabel: true,
            },

            axisLabel: {
              //轴上的字 字体、样式
              textStyle: {
                color: "#FFFFFF",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "单位：WM",
            nameTextStyle: {
              color: "#FFFFFF",
              align: "left",
            },
            axisLine: { onZero: false, show: true },
            axisLabel: {
              //轴上的字 字体、样式
              textStyle: {
                color: "#FFFFFF",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
              },
            },
          },
        ],
        series: [
          {
            name: "预测",
            type: "bar",
            barWidth: 14,
            data: [20, 52, 200, 334, 390, 330, 220, 160],
            itemStyle: {
              normal: {
                //这里设置柱形图圆角 [左上角，右上角，右下角，左下角]
                barBorderRadius: [100, 100, 0, 0],
                color: proxy.$echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "#22CCE2",
                  },
                  {
                    offset: 1,
                    color: "#09B66D",
                  },
                ]),
              },
            },
          },
        ],
      },
    });

    const unitChart = async () => {
      // 渲染图表
      const chart = proxy.$echarts.init(document.getElementById("unit"));
      chart.setOption(unit.options);
      window.onresize = () => {
        chart.resize();
      };
    };

    const changeCrew = () => {
      let current = [];
      if (crewRadio.value == "预测") {
        console.log(unit);
      } else if (crewRadio.value == "实际") {
      }
      for (let i = 0; i < 8; i++) {
        current.push(Math.trunc((Math.random() * 100) / 0.5));
      }
      unit.options.series[0].name = crewRadio.value;
      unit.options.series[0].data = current;
      unit.options.legend.data = [crewRadio.value];

      nextTick(() => {
        unitChart();
      });
    };

    const changeCrewType = () => {
      let current = [];
      for (let i = 0; i < 8; i++) {
        current.push(Math.trunc((Math.random() * 100) / 0.5));
      }
      unit.options.series[0].data = current;
      nextTick(() => {
        unitChart();
      });
    };

    const changeTrack = () => {
      if (dateTrack.value == 1) {
        track.options.xAxis.data = [
          "00:00",
          "01:00",
          "02:00",
          "03:00",
          "04:00",
          "05:00",
          "06:00",
          "07:00",
          "08:00",
          "09:00",
          "10:00",
        ];
        track.options.series = [
          {
            name: "预测数据",
            data: [1, 15, 52, 100, 23, 55, 75, 23, 55, 23, 5],
            type: "line",
            smooth: true,
          },
          {
            name: "实际数据",
            data: [55, 75, 23, 55, 23, 5, 0, 67, 23, 100, 5],
            type: "line",
            smooth: true,
          },
        ];
        nextTick(() => {
          trackChart();
        });
      } else if (dateTrack.value == 2) {
        track.options.xAxis.data = [
          "一月",
          "二月",
          "三月",
          "四月",
          "五月",
          "六月",
          "七月",
          "八月",
          "九月",
          "十月",
          "十一月",
        ];
        track.options.series = [
          {
            name: "预测数据",
            data: [100, 1, 15, 75, 23, 52, 23, 55, 55, 23, 5],
            type: "line",
            smooth: true,
          },
          {
            name: "实际数据",
            data: [5, 75, 23, 100, 23, 5, 0, 55, 67, 23, 55],
            type: "line",
            smooth: true,
          },
        ];
        nextTick(() => {
          trackChart();
        });
      } else if (dateTrack.value == 3) {
        track.options.xAxis.data = [
          "2012年",
          "2013年",
          "2014年",
          "2015年",
          "2016年",
          "2017年",
          "2018年",
          "2019年",
          "2020年",
          "2021年",
          "2022年",
        ];
        track.options.series = [
          {
            name: "预测数据",
            data: [15, 15, 15, 15, 15, 75, 15, 15, 15, 15, 5],
            type: "line",
            smooth: true,
          },
          {
            name: "实际数据",
            data: [15, 15, 25, 15, 15, 54, 15, 15, 75, 15, 65],
            type: "line",
            smooth: true,
          },
        ];
        nextTick(() => {
          trackChart();
        });
      }
    };

    const setDatas = () => {
      let maps = [],
        maps2 = [];
      for (var i = 0; i < 200; i++) {
        maps.push(randomData(1));
        maps2.push(randomData(2));
      }

      temperature.options.series[0].data = maps;
      temperature.options.series[1].data = maps2;

      setTimeout(() => {
        temperatureChart();
      }, 50);
    };

    onMounted(() => {
      nextTick(() => {
        trackChart();

        timelyChart();
        setDatas();
        unitChart();
      });
    });
    return {
      track,
      trackChart,
      dateType,
      dateTrack,
      changeTrack,
      temperatureChart,
      setDatas,
      temperature,
      timely,
      timelyChart,
      dateTemperature,
      randomData,
      dataList,
      crew,
      crewList,
      crewRadio,
      unit,
      unitChart,
      changeCrew,
      changeCrewType,
    };
  },
};
</script>

<style lang="scss" scoped>
.work_container {
  display: flex;
  justify-content: space-between;
}
.work_page {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  .track_style {
    width: 1160px;
  }
  #track {
    width: 100%;
    height: 409px;
  }
  #temperature {
    width: 100%;
    height: 409px;
  }
  .timely_style {
    width: 450px;
  }
  #timely {
    width: 100%;
    height: 310px;
    margin-top: 20px;
  }
  #unit {
    width: 100%;
    height: 410px;
  }
  .echarts_container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    box-shadow: inset 0px 0px 10px 0px #3767ad;
    background: #0b183c;
    margin-bottom: 30px;
    border-radius: 8px;
    padding: 20px 0;
    .echarts_title_container {
      width: 100%;

      .echarts_title {
        font-size: 16px;
        font-family: Source Han Sans CN-Medium, Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-indent: 2em;

        &::before {
          display: block;

          content: "";
          width: 4px;
          height: 25px;
          background: linear-gradient(135deg, #0081ff 0%, #22cce2 100%);
          border-radius: 0px 2px 2px 0px;
        }
      }
      &:deep(.el-input__wrapper) {
        background-color: #0b183c !important;
        border: 1px solid #8291a9;
        width: 130px;
        margin-right: 17px;
      }
    }
  }
  .crewRadio {
    margin: 20px 25px;
    &:deep(.el-radio-button__inner) {
      background: #8291a9;
      border: 1px solid #8291a9;
    }
  }
}
</style>
